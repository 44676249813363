<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-row class="align-items-center">
          <b-col>
            <h3 class="m-0">Gerenciamento de usuários</h3>
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" @click="novoUsuario"> Novo </b-button>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body>
        <b-table
          :items="usuarios"
          :fields="fields"
          :busy="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
          show-empty
        >
          <template #empty>
            <div class="text-center">Nenhum usuário encontrado.</div>
          </template>

          <template v-slot:cell(roles)="data">
            {{ data.value.map((role) => role.name).join(", ") }}
          </template>

          <template v-slot:cell(gerente)="data">
            {{ data.value?.nome }}
          </template>

          <template v-slot:cell(status)="data">
            <b-badge :variant="data.value === 'A' ? 'success' : 'danger'">{{
              data.value === "A" ? "Ativo" : "Inativo"
            }}</b-badge>
          </template>

          <template v-slot:cell(acao)="data">
            <b-dropdown
              variant="clean"
              size="small"
              toggle-class="btn-icon"
              no-caret
            >
              <template #button-content>
                <i class="fas fa-ellipsis-h"></i>
              </template>

              <b-dropdown-item @click="alterarSenhaUsuario(data.item)"
                >Alterar senha</b-dropdown-item
              >
              <b-dropdown-item @click="editarUsuario(data.item)"
                >Editar</b-dropdown-item
              >
              <b-dropdown-item @click="excluir(data.item.id)" variant="danger"
                >Excluir</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </b-card-body>

      <b-card-footer>
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            class="m-0"
          ></b-pagination>

          <div class="d-flex align-items-center flex-wrap">
            <b-select v-model="perPage" size="sm" class="mr-4">
              <option
                v-for="option in [20, 50, 100]"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </b-select>

            <span>
              Exibindo de {{ (currentPage - 1) * perPage + 1 }} até
              {{ currentPage * perPage }} de {{ totalRows }} registros.
            </span>
          </div>
        </div>
      </b-card-footer>
    </b-card>

    <usuario-formulario
      ref="mdlUsuarioFormulario"
      :funcoes="funcoes"
      :gerentes="gerentes"
      @atualizar="getUsuarios"
    ></usuario-formulario>

    <usuario-alterar-senha ref="mdlUsuarioAlterarSenha"></usuario-alterar-senha>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import UsuarioService from "@/core/services/usuario.service";
import UsuarioFormulario from "./UsuarioFormulario.vue";
import UsuarioAlterarSenha from "./UsuarioAlterarSenha.vue";
import Swal from "sweetalert2";

export default {
  name: "usuarios",

  components: {
    UsuarioFormulario,
    UsuarioAlterarSenha,
  },

  data() {
    return {
      usuarios: [],
      funcoes: [],
      gerentes: [],
      loading: false,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
          class: "text-center align-middle",
        },
        { key: "nome", label: "Nome", sortable: true, class: "align-middle" },
        {
          key: "usuario",
          label: "Login",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "roles",
          label: "Perfil",
          sortable: true,
          class: "text-center align-middle",
        },
        {
          key: "gerente",
          label: "Supervisor",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "text-center align-middle",
        },
        { key: "acao", label: "Ação", class: "text-center align-middle" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Usuários",
        route: { name: "usuarios" },
      },
    ]);

    this.getUsuarios();
  },

  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.getUsuarios();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getUsuarios() {
      this.loading = true;

      await UsuarioService.getUsuarios({
        page: this.currentPage,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        filter: this.filter,
      })
        .then(({ data }) => {
          this.usuarios = data.usuarios;
          this.funcoes = data.funcoes;
          this.gerentes = data.gerentes;
          this.totalRows = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    novoUsuario() {
      this.$refs.mdlUsuarioFormulario.exibirModal();
    },

    editarUsuario(usuario) {
      this.$refs.mdlUsuarioFormulario.exibirModal(usuario);
    },

    alterarSenhaUsuario(usuario) {
      this.$refs.mdlUsuarioAlterarSenha.exibirModal(usuario);
    },

    async excluir(id) {
      Swal.fire({
        title: "Atenção!",
        text: "Deseja realmente excluir este usuário?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.value) {
          await UsuarioService.excluir(id).then(() => {
            Swal.fire("Sucesso!", "Usuário excluído com sucesso.", "success");
            this.getUsuarios();
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.custom-select-sm {
  height: calc(1.244em + 1.1rem + 2px) !important;
  width: 70px !important;
}
</style>
