<template>
  <div>
    <b-modal
      ref="mdlUsuarioFormulario"
      :title="title"
      ok-title="Salvar"
      cancel-title="Cancelar"
      size="lg"
      @ok.prevent="salvar"
      @hidden="limpar"
      @shown="validacao"
      centered
    >
      <form ref="frmUsuarioFormulario">
        <b-row>
          <b-col>
            <b-form-group label="Nome:" label-for="usu-nome">
              <b-form-input
                id="usu-nome"
                name="nome"
                v-model="formulario.nome"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Login:" label-for="usu-usuario">
              <b-form-input
                id="usu-usuario"
                name="usuario"
                v-model="formulario.usuario"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <template v-if="!formulario.id">
          <b-row>
            <b-col>
              <b-form-group label="Senha:" label-for="usu-senha">
                <b-input-group size="sm">
                  <b-form-input
                    id="usu-senha"
                    name="senha"
                    :type="exibirSenha ? 'text' : 'password'"
                    v-model="formulario.senha"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      @click="exibirSenha = !exibirSenha"
                      variant="outline-secondary"
                      tabindex="-1"
                    >
                      <b-icon
                        :icon="exibirSenha ? 'eye-slash-fill' : 'eye-fill'"
                      ></b-icon>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Confirmar senha:"
                label-for="usu-confirmar-senha"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="usu-confirmar-senha"
                    name="confirmarSenha"
                    :type="exibirConfirmarSenha ? 'text' : 'password'"
                    v-model="formulario.confirmarSenha"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      @click="exibirConfirmarSenha = !exibirConfirmarSenha"
                      variant="outline-secondary"
                      tabindex="-1"
                    >
                      <b-icon
                        :icon="
                          exibirConfirmarSenha ? 'eye-slash-fill' : 'eye-fill'
                        "
                      ></b-icon>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="mb-7">
            <usuario-gerador-senha
              @senha-gerada="senhaGerada"
            ></usuario-gerador-senha>
          </div>
        </template>

        <b-row>
          <b-col col md="6">
            <b-form-group label="Perfil:" label-for="usu-funcao">
              <b-form-select
                id="usu-funcao"
                name="funcao"
                v-model="formulario.funcao"
                :options="funcoes"
                value-field="id"
                text-field="name"
                size="sm"
                @change="perfilAtualizado"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >-- Selecione um perfil --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col v-show="isColaborador">
            <b-form-group label="Gerente:" label-for="usu-gerente">
              <b-form-select
                id="usu-gerente"
                name="gerente"
                v-model="formulario.gerente"
                :options="gerentesFiltrados"
                value-field="id"
                text-field="nome"
                size="sm"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >-- Selecione um gerente --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="isColaborador">
          <b-col cols="9">
            <b-form-group label="Assinatura:" label-for="usu-assinatura">
              <b-form-file
                id="usu-assinatura"
                ref="usu-assinatura"
                name="assinatura"
                accept="image/jpeg,image/png"
                size="sm"
                placeholder="Escolha um arquivo ou arraste aqui"
                drop-placeholder="Solte o arquivo aqui"
                browse-text="Procurar"
                @change="obterAssinatura"
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label-for="usu-visualizar-assinatura">
              <template #label> &nbsp; </template>

              <b-button
                id="usu-visualizar-assinatura"
                variant="primary"
                size="sm"
                block
                @click="exibirAssinatura(formulario.assinatura)"
                :disabled="!formulario.assinatura"
              >
                Visualizar assinatura
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <usuario-assinatura-digital
      ref="mdlUsuarioAssinaturaDigital"
    ></usuario-assinatura-digital>
  </div>
</template>

<script>
import UsuarioService from "@/core/services/usuario.service";
import UsuarioGeradorSenha from "./UsuarioGeradorSenha.vue";
import UsuarioAssinaturaDigital from "./UsuarioAssinaturaDigital.vue";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "usuario-formulario",

  props: {
    funcoes: {
      type: Array,
      required: true,
    },
    gerentes: {
      type: Array,
      required: true,
    },
  },

  components: {
    UsuarioGeradorSenha,
    UsuarioAssinaturaDigital,
  },

  data() {
    return {
      formulario: {
        id: null,
        nome: "",
        usuario: "",
        senha: "",
        confirmarSenha: "",
        funcao: "",
        gerente: "",
        assinatura: "",
      },
      exibirSenha: false,
      exibirConfirmarSenha: false,
      idFuncaoColaborador: 3,
      senhaValidators: {
        validators: {
          notEmpty: {
            message: "Preenchimento obrigatório.",
          },
          stringLength: {
            min: 6,
            max: 20,
            message: "A senha deve conter entre 6 e 20 caracteres.",
          },
        },
      },
      confirmarSenhaValidators: {
        validators: {
          notEmpty: {
            message: "Preenchimento obrigatório.",
          },
          identical: {
            message: 'Os campos "Senha" e "Confirmar Senha" devem ser iguais.',
            compare: function () {
              return document.querySelector("#usu-senha").value;
            },
          },
        },
      },
    };
  },

  methods: {
    exibirModal(usuario) {
      if (usuario) {
        this.formulario.id = usuario.id;
        this.formulario.nome = usuario.nome;
        this.formulario.usuario = usuario.usuario;
        this.formulario.funcao = usuario.roles[0]?.id;
        this.formulario.gerente = usuario.gerente?.id;
      }

      this.$refs.mdlUsuarioFormulario.show();
    },

    async salvar() {
      let formulario = { ...this.formulario };

      if (!formulario.assinatura) {
        delete formulario.assinatura;
      }

      await this.fv.validate().then((status) => {
        if (status === "Valid") {
          UsuarioService.salvar(formulario)
            .then(() => {
              this.$emit("atualizar");
              this.$refs.mdlUsuarioFormulario.hide();

              Swal.fire({
                icon: "success",
                title: "Sucesso!",
                text: "Usuário salvo com sucesso.",
              });
            })
            .catch((error) => {
              if (error.response.status === 422) {
                Object.entries(error.response.data.errors).forEach(
                  ([key, value]) => {
                    this.fv
                      .updateValidatorOption(key, "blank", "message", value[0])
                      .updateFieldStatus(key, "Invalid", "blank");
                  }
                );
              }
            });
        }
      });
    },

    validacao() {
      const frmUsuarioFormulario = this.$refs.frmUsuarioFormulario;

      this.fv = formValidation(frmUsuarioFormulario, {
        fields: {
          nome: {
            validators: {
              notEmpty: {
                message: "Preenchimento obrigatório.",
              },
              blank: {},
            },
          },
          usuario: {
            validators: {
              notEmpty: {
                message: "Preenchimento obrigatório.",
              },
              stringLength: {
                min: 3,
                max: 50,
                message:
                  "O login deve ter de 3 a 50 caracteres contendo apenas letras e números.",
              },
              regexp: {
                regexp: /^[a-zA-Z0-9]+$/i,
                message:
                  "O login deve ter de 3 a 50 caracteres contendo apenas letras e números.",
              },
              blank: {},
            },
          },
          funcao: {
            validators: {
              notEmpty: {
                message: "Preenchimento obrigatório.",
              },
              blank: {},
            },
          },
          gerente: {
            validators: {
              notEmpty: {
                enabled: false,
                message: "Preenchimento obrigatório.",
              },
              blank: {},
            },
          },
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap(),
        },
      });

      if (!this.formulario.id) {
        this.fv.addField("senha", this.senhaValidators);
        this.fv.addField("confirmarSenha", this.confirmarSenhaValidators);
      }
    },

    limpar() {
      this.formulario.id = null;
      this.formulario.nome = "";
      this.formulario.usuario = "";
      this.formulario.senha = "";
      this.formulario.confirmarSenha = "";
      this.formulario.funcao = "";
      this.formulario.gerente = "";
      this.formulario.assinatura = "";
    },

    senhaGerada(senha) {
      this.formulario.senha = senha;
      this.formulario.confirmarSenha = senha;
    },

    exibirAssinatura(assinatura) {
      this.$refs.mdlUsuarioAssinaturaDigital.exibirAssinaturaDigital(
        assinatura
      );
    },

    obterAssinatura(event) {
      if (event.target.files.length) {
        this.formulario.assinatura = event.target.files[0];
      } else {
        this.formulario.assinatura = "";
      }
    },

    perfilAtualizado() {
      this.formulario.gerente = "";
      this.formulario.assinatura = "";
      this.$refs["usu-assinatura"].reset();
    },
  },

  computed: {
    title() {
      return this.formulario.id ? "Editar usuário" : "Novo usuário";
    },

    gerentesFiltrados() {
      return this.gerentes.filter(
        (gerente) => gerente.id !== this.formulario.id
      );
    },

    isColaborador() {
      return parseInt(this.formulario.funcao) === this.idFuncaoColaborador;
    },
  },

  watch: {
    "formulario.funcao": function () {
      if (this.fv) {
        // this.fv.updateFieldStatus("gerente", "NotValidated");
        if (this.isColaborador) {
          this.fv.enableValidator("gerente");
        } else {
          this.formulario.gerente = "";
          this.fv.disableValidator("gerente");
        }
      }
    },
  },

  beforeDestroy: function () {
    if (this.fv) {
      this.fv.destroy();
    }
  },
};
</script>

<style scoped>
/deep/ .custom-checkbox .custom-control-label::before {
  top: 0.1rem !important;
}
/deep/ .custom-checkbox .custom-control-label::after {
  top: 0.1rem !important;
}
</style>
